<template>
 <div>
  <div id="app"  class='' :class='{"dark":$store.state.dark }' v-if="ready">
  <div v-if="$root.Brender" class="pdf-cont-usage" style="width: 100%;
    height: 100vh;
    position: fixed;
    z-index: 99999999999;
    top: 1px;left:0px;">
    <button class="btn btn-secondary text-white" @click="$root.Brender = false" style="position: fixed;
    top: 33px;
    right: 5px;z-index:9999999999;">&#10006;</button>
      <!-- <vue-pdf-app page-scale="50" pdf="https://fuse-gold-assets.fra1.digitaloceanspaces.com/fuseg/wp.pdf"></vue-pdf-app> -->
      <pdf-comp url="https://assets-hxy.fra1.digitaloceanspaces.com/fuseg/brc.pdf"></pdf-comp>


      </div>
      <div v-if="$root.render" class="pdf-cont-usage" style="width: 100%;
    height: 100vh;
    position: fixed;
    z-index: 99999999999;
    top: 1px;left:0px;">
    <button class="btn btn-secondary text-white" @click="$root.render = false" style="position: fixed;
    top: 33px;
    right: 5px;z-index:9999999999;">&#10006;</button>
      <!-- <vue-pdf-app page-scale="50" pdf="https://fuse-gold-assets.fra1.digitaloceanspaces.com/fuseg/wp.pdf"></vue-pdf-app> -->
<pdf-comp url="https://assets-hxy.fra1.digitaloceanspaces.com/fuseg/wp.pdf"></pdf-comp>


      </div>
<div v-if="$store.state.routeLoader"   class="position-fixed opOne">
<img src="@/assets/loader.gif" alt="" srcset="">
</div>
    
    <div class="row justify-content-end p-0 m-0" v-if='ready'>
        <div class="col-lg-12 p-0">
            <router-view/>          
        </div>
      </div>
      <div v-else>
        <div class="position-fixed opOne">
<img src="@/assets/loader.gif" alt="" srcset="">
</div>
      </div>
      <register-modal></register-modal>
      <!-- <explainer></explainer> -->
  </div>
  <div v-else>
<h1 class="text-center">Loading</h1>
  </div>
 </div>
</template>
<script>
export default {
  name: "App",
  components:{
    RegisterModal:() => import('@/components/RegisterModal.vue'),
    PdfComp:() => import('@/components/PdfComp.vue'),
    // Explainer:() => import('@/components/Explainer.vue'),

  },
  data() {
    return {
      ready: false,
      fMessage:`This verification link has either expired or already been used.<br>
Please check if your email address is already verified by clicking the "Get My Referral Link" button and entering your email address.`
    }
  },
  async mounted() {
    this.$store.dispatch("getleaderboardData")
    this.ready = false;
       await  this.loadApp();
       this.ready = true;

     },
      methods:{
        async loadApp() {
        console.log('the current route it',this.$route,)
     setTimeout(async() => {
            if(this.$route.name == "Email"){
             console.log('we are in email verification route')
             let dec = null
       dec = await this.$store.dispatch('verifyEmailProm', this.$route.params.id)
              
      console.log('home emal verify', dec)
      let message = null
      if (dec) {
        if (dec == 2) message = this.fMessage 
        else if (dec == 1) message = "Email Address has been verified Successfully";
        }else{
          message = 'Unable to verify email'
        }
        const SL = this.$root.Swal.mixin({
        customClass: {  confirmButton: 'btn btn-lg btn-primary mr-3', denyButton: 'btn btn-lg btn-primary'},
        buttonsStyling: false
        })
            if (dec == 1) {
        this.$router.push({name:"verified"})
        } else {
        SL.fire({
        title: message,
        showDenyButton: true,
        showCancelButton: false,
        showConfirmButton: (dec == 1 && !this.$store.state.prom) ? true : false,
        confirmButtonText: 'Login',
        denyButtonText: `Close`,
      }).then((result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
          setTimeout(() => {
            console.log('proceeding to login')
            this.$store.state.action = "signin"
            // this.$bvModal.show("modal-register")  
            this.store.state.modalRegister = true
          }, 
          1000 );
        } 
      })
      }
      
    }else{
      console.log("this is home route, not a verification route")
    }
     }, 1000);
      },
      }
}
</script>

<style lang="scss">

</style>
