
import {
getCachedData,searchRank,saveCachedData,
  verifyEmailProm,
getPromUsersPro,
getRewardConfig,
getPromUsers,
updateUsernames,
sendQrCode,
getNickName,
sendVerifEmail,
checkReferralID,
checkReferral,
sendMail,
getleaderboardData,
addRegInterest







} from './backend'

export default { 
  getleaderboardData,
  checkReferralID,
  checkReferral,
  sendVerifEmail,
  getNickName,
  updateUsernames,
  getRewardConfig,
  sendQrCode,
  getPromUsersPro,getPromUsers,
  addRegInterest,
getCachedData,searchRank,saveCachedData,
  verifyEmailProm,
  
  sendMail,
};