import Vue from 'vue'
import './assets/main.css'
import './assets/dark.css'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import App from './App.vue'
import router from './router'
import store from './store'
Vue.config.productionTip = false
import Swal from 'sweetalert2'
// import VueClazyLoad from 'vue-clazy-load'
Vue.config.productionTip = false
Vue.config.performance = true
import VueConfetti from 'vue-confetti'
Vue.use(VueConfetti)
// import VueLazyload from 'vue-lazyload'
// const loadimage = require('./assets/loader.gif')
// const errorimage = require('./assets/loader.gif')

// Vue.use(VueLazyload, {
//   preLoad: 1.3,
//   error: errorimage,
//   loading: loadimage,
//   attempt: 1
// })
// Vue.use(VueLazyload)
// Vue.use(VueClazyLoad)
new Vue({
  router,
  store,
  data:{
    Swal,
    render:false,
    Brender:false,
    Toast:null,
  },
  mounted() { 
    
    this.Toast = Swal.mixin({
      toast: true,
      position: 'top-end',
      showConfirmButton: false,
      timer: 6000,
      timerProgressBar: true,
      didOpen: (toast) => {
        toast.addEventListener('mouseenter', Swal.stopTimer)
        toast.addEventListener('mouseleave', Swal.resumeTimer)
      }
    })
   },
  render: h => h(App)
}).$mount('#app')
