import Vue from 'vue'
// import store from '../store'
function lazyLoad(view){
  return() => import(`../views/${view}.vue`)
}

import Home from '../views/Home.vue';
import VueRouter from 'vue-router'


Vue.use(VueRouter)

const routes = [
  { path: '/dashboard/admin', redirect: { name: 'UserManagement' }, meta:{ auth: true,allowedUsers:[4,5] } },
  {
    path: '/',
    name: 'Home',
    meta:{auth: false},
    component: Home
  },
  {
    path: '/referral/:ref',
    name: 'HomeRef',
    meta:{auth: false},
    component: Home
  },
  {
    path: '/partners',
    meta:{auth: false},
    name: 'Partners',
    component: lazyLoad("Partners")
  },
  {
    path: '/leaderboard',
    meta:{auth: false},
    name: 'Leaderboard',
    component: lazyLoad("Leaderboard")
  },
  
  {
    path: '/thankyou',
    meta:{auth: false},
    name: 'thankyou',
    component: lazyLoad("ThankYou")
  },
  {
    path: '/verified',
    meta:{auth: false},
    name: 'verified',
    component: lazyLoad("Verified")
  },
  {
    path: '/email-verification/:id',
    name: 'Email',
    meta:{auth: false},
    component: Home
  },


  {
    path: '/security-custody',
    meta:{auth: false},
    name: 'SecurityCustody',
    component: lazyLoad("SecurityCustody")
  },
  {
    path: '/key-features',
    meta:{auth: false},
    name: 'WhitePaper',
    component: lazyLoad("WhitePaper")
  },
  {
    path: '/privacy-policy',
    meta:{auth: false},
    name: 'PrivacyPolicy',
    component: lazyLoad("PrivacyPolicy")
  },
  {
    path: '/terms-conditions',
    meta:{auth: false},
    name: 'TermsConditions',
    component: lazyLoad("TermsConditions")
  },
  {
    path: '/goldx-terms-conditions',
    meta:{auth: false},
    name: 'GoldxTermsConditions',
    component: lazyLoad("GoldxTermsConditions")
  },
  {
    path: '/page-not-found',
    meta:{auth: false},
    name: '404',
    component: lazyLoad("404")
  },
  {
    path: '/cookie-policy',
    meta:{auth: false},
    name: 'CookiePolicy',
    component: lazyLoad("CookiePolicy")
  },
  {
    path: '/website-acceptable-use-policy',
    meta:{auth: false},
    name: 'Website Acceptable Use Policy',
    component: lazyLoad("WAUP")
  },
  {
    path: '/website-disclaimer',
    meta:{auth: false},
    name: 'Website Disclaimer',
    component: lazyLoad("WebsiteDisclaimer")
  },
  {
    path: '/ico-terms-and-conditions',
    meta:{auth: false},
    name: 'ICO Terms and Conditions',
    component: lazyLoad("ICOTAC")
  },
  {
    path: '/gdpr-privacy-policy',
    meta:{auth: false},
    name: 'GDPR Privacy Policy',
    component: lazyLoad("GDPRPP")
  },
  

 
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    setTimeout(() => {
      document.getElementById('app').scrollIntoView();      
    }, 2000);
}
})


export default router
